.full-width-card {
    width: 100%;
}

.flag-color-container {
    max-width: 800px;
    display: flex;
}

.flag-color-item {
    flex: 1;
}