.centered {
    display: flex;
    max-width: 500px;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: 0 auto;
}

.register-container {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  

.full-width {
    width: 100%
}

.submit-button {
    margin: 20px;
}