.full-width-form {
    width: 100%;
    height: 100%;
}

.no-ripple {
    --ripple-color: transparent;
  }

.no-ripple-no-hover {
    --ripple-color: transparent;
    --background-hover: var(--background);
  }

.fullscreen {
    --width:90% !important;
    --height:90% !important;
}

.modal-content-padding {
    padding: 24px
}

.popover-content{
  --width:80vw;
  --max-width:800px;
  z-index: 12;
}

ion-button[shape=circle] {
  --border-radius: 50%;
  --padding-start: 0;
  --padding-end: 0;
  width: 56px;
  height: 56px;
}

ion-modal#birthday-picker {
    --width: fit-content;
    --min-width: 250px;
    --height: fit-content;
    --border-radius: 6px;
}

ion-modal.modal-fullscreen {
    --width: 90vw;
    --height: 90vh;
}

ion-modal.modal-default.show-modal:last-of-type {
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

ion-note {
    display: block;
}